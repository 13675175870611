import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "search", "reset"];
  static searchTimeout = 200;

  repo = [];
  loading = false;

  initialize() {
    this.groundwaterPath = this.element.dataset.groundwaterPath;
    this.lakesAndRiversPath = this.element.dataset.stationsPath;
    this.groundwaterLabel = this.element.dataset.groundwaterLabel;
    this.lakesAndRiversLabel = this.element.dataset.stationsLabel;
  }

  connect() {
    this.setupTypeahead();
    this.formTarget.addEventListener("submit", (e) => {
      e.preventDefault();
      e.stopPropagation();

      const formValue = $(this.searchTarget).typeahead("val");
      const search = this.canonicalize(formValue);
      const results = this.filter(search);
      if (formValue && results.length) {
        this.handleSelect(e, results[0]);
      }
    });
  }

  reset() {
    $(this.searchTarget).typeahead("val", "");
    this.resetTarget.classList.add("d-none");
  }

  canonicalize(value) {
    return value
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  async fetchData(basePath) {
    const data = await fetch(this.jsonUrl(basePath));
    const json = await data.json();
    return json.map(this.withBaseUrl(basePath));
  }

  async loadRepo() {
    this.loading = true;
    const [groundwater, lakesAndRivers] = await Promise.all([
      this.fetchData(this.groundwaterPath),
      this.fetchData(this.lakesAndRiversPath),
    ]);

    this.repo = [...groundwater, ...lakesAndRivers].sort(this.order);
    this.loading = false;
  }

  order(a, b) {
    return a.label.localeCompare(b.label);
  }

  jsonUrl(path) {
    return path + ".json";
  }

  filter(query) {
    return this.repo.filter(
      ({ names, label }) =>
        this.canonicalize(label).includes(query) ||
        names.some((name) => name.includes(query))
    );
  }

  withBaseUrl(url) {
    return (suggestion) => ({ ...suggestion, baseUrl: url });
  }

  async remoteSource(query, syncResults, asyncResults) {
    const search = this.canonicalize(query);
    let results = syncResults;
    if (!this.repo.length) {
      await this.loadRepo();
      results = asyncResults;
    }

    const filtered = this.filter(search);
    if (!filtered.length || !query) {
      filtered.unshift(
        { label: this.lakesAndRiversLabel, baseUrl: this.lakesAndRiversPath },
        { label: this.groundwaterLabel, baseUrl: this.groundwaterPath }
      );
    }

    results(filtered);
  }

  handleSelect(_e, suggestion) {
    if (suggestion) {
      let url = suggestion.baseUrl;
      if (suggestion.key) url += `/${suggestion.key}`;

      window.location.href = url;
    }
  }

  handleKeyup(e) {
    // escape key
    if (e.keyCode === 27) {
      this.reset();
      return;
    }

    const method = e.target.value ? "remove" : "add";
    this.resetTarget.classList[method]("d-none");
  }

  handleRender(_e, suggestions = []) {
    // typeahead's empty templates do not work with async datasets
    // so we handle it manually
    if (suggestions.length || this.loading) return;

    const $target = $("#station-selector").find(".tt-empty");
    const $source = $("#station-selector")
      .find(".empty-placeholder")
      .clone(true, true);

    $source.removeClass("d-none");
    $target.children().append($source);
    $target.show();
  }

  setupTypeahead() {
    const $nextSiblings = $(this.searchTarget).nextAll();
    $(this.searchTarget)
      .typeahead(
        {
          hint: false,
          highlight: true,
          minLength: 0,
        },
        {
          name: "stations",
          displayKey: "label",
          source: this.remoteSource.bind(this),
          limit: Infinity,
          display: (item) => (item.key ? item.label : ""),
          templates: {
            suggestion: (item) => {
              return `<div class="${!item.key ? "placeholder-link" : ""}">${
                item.label
              }</div>`;
            },
          },
        }
      )
      .bind("typeahead:select", this.handleSelect.bind(this))
      .bind("typeahead:render", this.handleRender.bind(this))
      .on("keyup", this.handleKeyup.bind(this));

    const $target = $("#station-selector span.twitter-typeahead");
    $nextSiblings.removeClass("initially-hidden");
    $target.append($nextSiblings);
  }
}
