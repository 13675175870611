import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "legend"];

  connect() {
    $('[data-toggle="tooltip"]').tooltip();
    this.clickAway = this.clickAway.bind(this);
  }

  get buttonIcon() {
    return this.buttonTarget.querySelector(".icon");
  }

  toggle() {
    const visible = this.legendTarget.style.visibility !== "hidden";
    visible ? this.hide() : this.show();
  }

  show() {
    this.legendTarget.style.visibility = "visible";
    this.buttonIcon.classList.toggle("icon--info", false);
    this.buttonIcon.classList.toggle("icon--close", true);
    document.addEventListener("mousedown", this.clickAway, { once: true });
  }

  hide() {
    this.legendTarget.style.visibility = "hidden";
    this.buttonIcon.classList.toggle("icon--info", true);
    this.buttonIcon.classList.toggle("icon--close", false);
  }

  clickAway(event) {
    if (!this.buttonTarget.contains(event.target)) {
      this.hide();
    }
  }
}
