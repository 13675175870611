import { Controller } from "@hotwired/stimulus";
import { isFavorite, updateFavoriteInLocalStorage } from "@/utils/localStorage";

export default class extends Controller {
  static targets = ["input"];

  inputTargetConnected(el) {
    this.setFavoriteFromLocalStorage(el);
  }

  toggle(event) {
    const key = this.keyFromElement(event.target);
    updateFavoriteInLocalStorage(key, event.target.checked);
    this.inputTargets
      .filter((el) => this.keyFromElement(el) === key)
      .forEach((el) => (el.checked = event.target.checked));
  }

  setFavoritesFromLocalStorage() {
    this.inputTargets.forEach((el) => this.setFavoriteFromLocalStorage(el));
  }

  setFavoriteFromLocalStorage(el) {
    el.checked = isFavorite(this.keyFromElement(el));
  }

  keyFromElement(el) {
    return el.dataset.hydroFavoriteToggleKey;
  }
}
