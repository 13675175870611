import { Controller } from "@hotwired/stimulus";
import { isFavorite, getFavorites } from "@/utils/localStorage";

export default class extends Controller {
  static targets = ["search", "reset", "tab"];
  static searchTimeout = 200;

  initialize() {
    const { hydroFavoritesLakesAndRivers, hydroFavoritesGroundwater } =
      this.element.dataset;

    this.isFavorites =
      hydroFavoritesLakesAndRivers && hydroFavoritesGroundwater;
    this.lakesAndRiversPath = hydroFavoritesLakesAndRivers;
    this.groundwaterPath = hydroFavoritesGroundwater;

    this.urls = this.isFavorites
      ? [hydroFavoritesLakesAndRivers, hydroFavoritesGroundwater]
      : [window.location];
  }

  async connect() {
    if (this.isFavorites) {
      await this.loadAndPopulateFavorites();
    }

    this.setupTablesort();
    if (this.searchTarget.value) {
      this.searchOp();
    }
  }

  async loadAndPopulateFavorites() {
    const favs = getFavorites();
    if (!favs.length) {
      this.showEmptyFavorites();
      this.showPane();
      return;
    }

    const [lakesAndRivers, groundwaters] = await Promise.all([
      this.fetchHtml(this.lakesAndRiversPath),
      this.fetchHtml(this.groundwaterPath),
    ]);

    const metrics = Array.from(
      this.element.querySelectorAll("ul.metric-tabs li")
    ).map((el) => el.dataset.metric);

    metrics.forEach((metric) => {
      const dest = this.element.querySelector(
        `#${metric} table.stations-table tbody`
      );

      [
        ...this.getFavoriteRowsByMetric(metric, lakesAndRivers),
        ...this.getFavoriteRowsByMetric(metric, groundwaters),
      ]
        .sort(this.initialCompare)
        .forEach((row) => {
          // make sure hydro body col is shown
          row.querySelector("td.d-none")?.classList.remove("d-none");
          dest.appendChild(row);
        });
    });

    this.showPane();
  }

  showEmptyFavorites() {
    document
      .querySelectorAll("table.stations-table")
      .forEach((el) => el.remove());
    document
      .querySelectorAll(".empty-favorites")
      .forEach((el) => el.classList.remove("hidden"));
  }

  showPane() {
    document.querySelector(".tab-content.hidden").classList.remove("hidden");
  }

  // sort by hydro-body and station. Rows without measures should come last.
  initialCompare(elementA, elementB) {
    const aData = elementA.dataset;
    const bData = elementB.dataset;

    if (aData.measuredAt && !bData.measuredAt) return -1;
    if (!aData.measuredAt && bData.measuredAt) return 1;
    if (aData.hydroBody && !bData.hydroBody) return -1;
    if (!aData.hydroBody && bData.hydroBody) return 1;
    if (aData.hydroBody === bData.hydroBody)
      return aData.name.localeCompare(bData.name);

    return aData.hydroBody.localeCompare(bData.hydroBody);
  }

  getFavoriteRowsByMetric(metric, htmlString) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, "text/html");
    return Array.from(
      htmlDoc.querySelectorAll(`#${metric} table.stations-table tbody tr`)
    ).filter((row) => isFavorite(row.dataset.key));
  }

  reset() {
    this.searchTarget.value = "";
    this.resetTarget.classList.add("hidden");
    if (this.repo) {
      this.filterTable();
    }
  }

  switchTab() {
    if (this.repo) {
      const metric = event.currentTarget.getAttribute("href").replace(/#/, "");
      this.filterTable(metric);
    }
  }

  submit(event) {
    event.preventDefault();
  }

  search() {
    if (this.deferredSearch) {
      clearTimeout(this.search);
    }
    this.deferredSearch = setTimeout(
      () => this.searchOp(),
      this.constructor.searchTimeout
    );
  }

  searchOp() {
    if (!this.searchTarget.value.length > 1) return;

    if (this.searchTarget.value) {
      this.resetTarget.classList.remove("hidden");
    } else {
      this.resetTarget.classList.add("hidden");
    }

    if (this.repo) {
      this.filterTable();
    } else {
      this.populateAndFilter();
    }
  }

  async populateAndFilter() {
    const results = await Promise.all(this.urls.map(this.fetchJson));
    this.repo = results.reduce((acc, curr) => [...acc, ...curr], []);
    this.filterTable();
  }

  async fetchJson(path) {
    const res = await fetch(`${path}.json`, {
      headers: { Accept: "application/json" },
    });
    return res.json();
  }

  async fetchHtml(path) {
    const res = await fetch(path, { headers: { Accept: "text/html" } });
    return res.text();
  }

  filterTable(selectedMetric) {
    const value = this.canonicalize(this.searchTarget.value);
    const metric = selectedMetric
      ? selectedMetric
      : document.querySelector(".metric-tabs li.active").dataset["metric"];
    const visibleRows = this.filter(metric, value).map(
      (item) => `.${metric}_${item.key}`
    );

    this.hideAllVisible(metric);
    this.showMatching(visibleRows);
  }

  canonicalize(value) {
    return value
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  filter(metric, value) {
    return this.repo.filter((item) => {
      return item.names.some((name) => {
        return name.includes(value);
      });
    });
  }

  hideAllVisible(metric) {
    const selector = `#${metric} tbody tr, div[data-tab-id="${metric}"] tbody tr`;
    document.querySelectorAll(selector).forEach(function (item) {
      item.classList.add("hidden");
    });
  }

  showMatching(visibleRows) {
    if (visibleRows.length > 0) {
      document
        .querySelectorAll(visibleRows.join(", "))
        .forEach(function (item) {
          item.classList.remove("hidden");
        });
    }
  }

  setupTablesort() {
    // https://mottie.github.io/tablesorter/docs/example-parsers.html
    $(".table-sort").trigger("destroy");
    $.tablesorter.addParser({
      id: "values",
      type: "numeric",
      is: () => false,
      format: (_string, _table, cell) => {
        const match = cell.innerHTML.match(/strong>(.*?)</);
        if (match) {
          return match[1];
        } else {
          return "";
        }
      },
    });

    $(".table-sort").tablesorter({
      sortLocaleCompare: true,
      headers: { 0: { sorter: false } },
    });
  }
}
