import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.index = 0;
    this.visibleCols = this.getVisibleColumns()
    this.limit = this.getLimit()
    this.showIfNeeded();
    this.installTouchEventHandlers();
  }

  installTouchEventHandlers() {
    console.log(this.element);
    this.element.addEventListener('touchstart', this.handleTouchStart.bind(this));
    this.element.addEventListener('touchend', this.handleTouchEnd.bind(this));
  }

  handleTouchStart(event) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  handleTouchEnd(event) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    this.handleSwipe();
  }

  handleSwipe() {
    if (this.touchendX < this.touchstartX) {
      this.moveRight();

    }
    if (this.touchendX > this.touchstartX) {
      this.moveLeft();
    }
  }

  showIfNeeded() {
    if(this.limit > 0) {
      // console.log(`visibleCols: ${this.visibleCols}, limit ${this.limit}`);
      this.element.querySelector(".carousel").classList.remove("hidden");
      this.adaptIndicators();
    }
  }

  adaptIndicators() {
    this.limit = this.getLimit();
    this.visibleCols = this.getVisibleColumns()
    Array.from(this.element.querySelectorAll("ol.carousel-indicators li")).forEach((el,i) => {
      if (i > this.limit) {
        el.classList.add("hidden");
      } else {
        el.classList.remove("hidden");
      }
    })
  }

  move(event) {
    const nextIndex = parseInt(event.target.dataset["target"]);
    this.moveTo(nextIndex);
  }

  moveLeft() {
    this.moveTo(this.nextIndex("left"));
  }

  moveRight() {
    this.moveTo(this.nextIndex("right"));
  }

  moveTo(nextIndex) {
    const cells = this.getCells(nextIndex);
    console.log(`moving to ${nextIndex}`);
    this.hideCells();
    this.showCells(cells);
    this.toggleIndicator(nextIndex);
    this.index = nextIndex;
  }

  nextIndex(direction) {
    if(direction === "left") {
      if(this.index > 0) {
        return this.index - 1;
      } else {
        return this.limit;
      }
    }

    if (direction === "right") {
      if(this.index < this.limit) {
        return this.index + 1;
      } else {
        return 0;
      }
    }
  }

  hideCells() {
    Array
      .from(this.element.querySelectorAll("th:nth-child(n+2), td:nth-child(n+2)"))
      .forEach(e => e.style.display = "none")
  }

  showCells(cells) {
    Array.from(this.element.querySelectorAll(cells))
        .forEach(el => el.style.display = "table-cell")
  }

  getCells(index) {
    const offset = 2 + this.visibleCols * index;
    return Array
      .from({ length: this.visibleCols }, (x,i) => offset + i)
      .map((i) => `th:nth-child(${i}), td:nth-child(${i})`)
  }

  toggleIndicator(index) {
    Array
      .from(this.element.querySelectorAll(".carousel-indicators li"))
      .forEach(e => e.classList.remove("active"))
    this.element.querySelector(`.carousel-indicators li:nth-child(${index + 1})`).classList.add("active")
  }

  getLimit() {
    const columnCount = Array.from(this.element.querySelectorAll("thead th")).length - 1;
    const val = columnCount / this.visibleCols
    if(Number.isInteger(val)) { return val - 1; }
    return Math.floor(val);
  }

  getVisibleColumns() {
    const headers = Array.from(this.element.querySelectorAll("thead th"))
    return headers.filter(e => jQuery(e).is(":visible")).length - 1;
  }

}
