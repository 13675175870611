const HYDRO_STATION_FAVORITES_KEY = "hydro_station_favorites";

export function updateFavoriteInLocalStorage(key, isFavorit) {
  let favorites = getFavorites();
  if (isFavorit) {
    favorites.push(key);
  } else {
    favorites = favorites.filter((favorit) => favorit !== key);
  }
  setFavorites(favorites);
}

export function isFavorite(key) {
  return getFavorites().indexOf(key) > -1;
}

export function getFavorites() {
  return JSON.parse(localStorage.getItem(HYDRO_STATION_FAVORITES_KEY)) || [];
}

function setFavorites(favoriteList) {
  localStorage.setItem(
    HYDRO_STATION_FAVORITES_KEY,
    JSON.stringify(favoriteList)
  );
}
