import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  MOBILE_WIDTH = 787;
  connect() {
    // prevents scrolling to previous position when reloading page
    history.scrollRestoration = "manual";
    if (this.isMobile()) {
      // trigger resize to ensure tabs get "collapsified"
      this.dispatchResize();
      // trigger resize to ensure proper plot size after collapsify
      setTimeout(() => this.dispatchResize(), 1000);
    }
    this.resolveAnchor();
  }

  selectParam(event) {
    if (event.currentTarget.classList.contains("collapse-closed")) {
      // if an accordion gets closed in mobile view
      this.dispatchResize();
      return;
    }
    const newParam = event.currentTarget.getAttribute("href").replace("#", "");
    this.updateHash(newParam, this.getRangeFromHash());
    this.updateParam();
    this.dispatchResize();
  }

  selectRange(event) {
    this.setHash(event.currentTarget.getAttribute("href"));
    this.updateRange();
    this.dispatchResize();
  }

  selectRangeMobile(event) {
    // on small screens tabs are collapsed to select inputs
    const [param, newRange] = event.target.value.split("-");
    this.updateHash(param, newRange);
    this.updateRange();
    // reset dropdown to static original value
    const originalValue = event.target.getAttribute("data-value");
    event.target.value = originalValue;
    this.dispatchResize();
  }

  updateRange() {
    let rangePlotElement = this.getRangePlotElement();
    if (!rangePlotElement) {
      rangePlotElement = this.getFirstRangeElement();
    }
    $(rangePlotElement).tab("show");
    this.setPlotController();
  }

  getFirstRangeElement() {
    const paramTabGroup = this.getParamTabGroup(this.getParamFromHash());
    if (!paramTabGroup) return null;

    const id = paramTabGroup.querySelector(".tab-pane:not(.disabled)")?.id;
    this.updateHash(this.getParamFromHash(), id?.split("-").pop());
    return this.getRangePlotElement();
  }

  getRangePlotElement() {
    return this.element.querySelector(
      `li a:not(.disabled)[href='${this.getHash()}']`
    );
  }

  updateParam() {
    let parameterTabElement = this.getParamTabElement();
    if (!parameterTabElement) {
      parameterTabElement = this.getFirstParamTabElement();
    }
    $(parameterTabElement).tab("show");
    this.setPlotController();
    this.updateRange();
  }

  getFirstParamTabElement() {
    return null;
  }

  getParamTabGroup(param) {
    return this.element.querySelector(
      `div#${param}, div[data-tab-id="${param}"]`
    );
  }

  getParamTabElement() {
    return this.element.querySelector(`a[href="#${this.getParamFromHash()}"]`);
  }

  setPlotController() {
    this.element
      .querySelectorAll(`${this.getHash()} .station-plot`)
      .forEach((elem) => {
        elem.dataset.controller = "hydro-stations-plots";
      });
  }

  replacePlotUrl(event) {
    const newUrl = event.target.value;
    const plotElement =
      event.target.parentElement.parentElement.querySelector(".station-plot");
    plotElement.setAttribute("data-hydro-stations-plots-url", newUrl);

    const parent = plotElement.parentNode;
    parent.removeChild(plotElement);
    parent.prepend(plotElement);
  }

  resolveAnchor() {
    if (window.location.hash) {
      this.updateParam();
      this.updateRange();
      this.element.scrollIntoView();
    }
  }

  updateHash(param, range) {
    this.setHash(`#${param}-${range}`);
  }

  setHash(hash) {
    // prevents scrolling new set hash
    history.pushState({}, "", hash);
  }

  getParamFromHash() {
    return this.getHash().split("-")[0].replace("#", "");
  }

  getRangeFromHash() {
    let [_, range] = this.getHash().split("-");
    return range;
  }

  getHash() {
    let hash = window.location.hash;
    if (!hash) {
      const [_, param] = this.element
        .querySelector("[class^=metric]")
        ?.href.split("#");
      const paramTabGroup = this.getParamTabGroup(param);
      const range = paramTabGroup
        .querySelector(".tab-pane.active")
        ?.id.split("-")
        .pop();
      hash = `#${param}-${range}`;
    }
    return hash;
  }

  dispatchResize() {
    window.dispatchEvent(new Event("resize"));
  }

  isMobile() {
    return $(window).width() <= this.MOBILE_WIDTH;
  }
}
