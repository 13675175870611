import { Controller } from "@hotwired/stimulus";
import { renderPlot, resizePlot } from "bafu-sam-shared";
import { format } from "date-fns";
import de from "date-fns/locale/de";

export default class extends Controller {
  connect() {
    this.load();
    window.addEventListener("resize", this.resizePlotLegend.bind(this));
    screen.orientation.addEventListener("change", (_) => { setTimeout(this.render.bind(this), 300) });
  }

  disconnect() {
    window.removeEventListener("resize", this.resizePlotLegend);
  }

  load() {
    fetch(this.data.get("url") + ".json")
      .then((response) => response.json())
      .then((json) => this.json = json)
      .then(this.render.bind(this));
  }

  render() {
    const { plot, hoverInfo } = this.adjust(JSON.parse(JSON.stringify(this.json)));
    const formatDate = (date, dateFormat) =>
      format(date, dateFormat, { locale: de });
    renderPlot(this.element, { plot, hoverInfo }, formatDate, {
      displayModeBar: false,
    });
  }

  adjust(json) {
    if (!/monthly/.test(this.data.get("url"))) {
      return json;
    }
    const shrinkFactor = 40;
    const relativeSize = (reference, size) => {
      return Math.min((reference / shrinkFactor), size)
    }
    const reference = window.innerWidth;

    json.plot.data
      .filter((data) => data.type == 'scatter')
      .forEach((scatter) => {
        scatter.marker.size = relativeSize(reference, scatter.marker.size)
    })
    return json;
  }

  resizePlotLegend() {
    resizePlot(this.element);
  }
}
