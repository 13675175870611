import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  download() {
    const value = this.element.querySelector("option:checked").value
    if(value) {
      window.open(value, "_blank")
    }
  }
}
